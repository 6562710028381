(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.HtmlComponentSkin'] = {
  "react": [
    [
      "div",
      "iFrameHolder",
      [],
      {}
    ]
  ],
  "css": {
    "%iFrameHolder": "width:100%;height:100%;",
    "%iFrameHolder > iframe": "position:absolute;",
    "%[data-state=\"noContent\"]": "background-color:#A5A5A5;"
  }
}

        return skins;
    }));